import { _get, _post, _put } from "./API";
// const baseURL = 'http://192.168.0.13:5100';
// const baseURL = 'http://26.248.107.103:5100';
// const baseURL = 'https://test.ebenfed.in/api/agristack';
const url = 'https://agristack.bwsapp.com/'
const baseURL = `${url}api/agristack`;

export const MasterAPI = {

    
    getAggregateData: async (sign: string) => {
      return await _get(`${baseURL}/${sign}`);
    },
    getAllData: async (user: string) => {
      return await _get(`${baseURL}/search/${user}`);
    },
    submitQuery: async (body: any) => {
      return await _post(`${url}api/conversation/data/query`, body);
    },
}